import React, { useRef, useState, useEffect } from "react";
import { setStorage, getStorage, deleteStorage } from "../modals/Storage";
import {
  API_ACCESS_URL,
  AVATARS_ARR,
  getRandomInt,
  openNewPage,
} from "../modals/Constants";
import { Link, useNavigate } from "react-router-dom";
import BottomNavbar from "../other-components/BottomNavbar/Navbar";
import NoticeDialog from "../dialogs/NoticeDialog2";
import SlideShow from "../other-components/SlideShow";
import styles from "../css/Screens.module.css";
import general from "../css/General.module.css";
import ImportantNotice from "../other-components/ImportantNotice/Notice";

function Home() {
  const navigate = useNavigate();
  const [isLoadingShow, setLoadingShow] = useState(false);
  const [viewBoxNumber, setViewBoxNumber] = useState(0);

  const [pageConst, setConstants] = useState({
    accountId: "",
    accountBalance: 0,
    isDataLoaded: false,
    recordList: [],
    sliderImages: [],
    winnersRecordList: [],
  });

  const [noticeDialogConst, setNoticeDialogConst] = useState({
    noticeTitle: "",
    noticeNote: "",
    noticeDialogShow: false,
  });

  const refreshData = () => {
    setConstants((previousState) => {
      return { ...previousState, isDataLoaded: false };
    });
  };

  const updateSliderImagesArr = (data) => {
    if (data.length > 0) {
      setConstants((previousState) => {
        return { ...previousState, sliderImages: data };
      });
    }
  };

  const showNotice = (data) => {
    if (data.length > 0) {
      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeTitle: data[0]["notice_title"] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeNote: data[0]["notice_note"] };
      });

      setNoticeDialogConst((previousState) => {
        return { ...previousState, noticeDialogShow: true };
      });
    }
  };

  const updateNoticeDialogState = (data) => {
    setNoticeDialogConst((previousState) => {
      return { ...previousState, noticeDialogShow: data };
    });
  };

  function getAccountInfo(accountId, accountAuthSecret) {
    const fecthApiData = async (url, authSecret) => {
      try {
        const res = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Route: "route-account-info",
            AuthToken: authSecret,
          },
        });
        const data = await res.json();

        setLoadingShow(false);

        if (data.status_code == "success") {
          let accountAvatarId = data.data[0]["account_avatar_id"];
          let accountUserName = data.data[0]["account_user_name"];
          let accountBalance = data.data[0]["account_balance"];
          let accountWinningBalance = data.data[0]["account_w_balance"];
          let accountCommissionBalance = data.data[0]["account_c_balance"];
          let accountLevel = data.data[0]["account_level"];
          let accountLastActive = data.data[0]["account_last_active"];

          let service_app_status = data.data[0]["service_app_status"];
          let service_min_recharge = data.data[0]["service_min_recharge"];
          let service_min_withdraw = data.data[0]["service_min_withdraw"];
          let service_recharge_option = data.data[0]["service_recharge_option"];
          let service_telegram_url = data.data[0]["service_telegram_url"];
          let service_payment_url = data.data[0]["service_payment_url"];
          let service_livechat_url = data.data[0]["service_livechat_url"];
          let service_imp_message = data.data[0]["service_imp_message"];
          let service_app_download_url =
            data.data[0]["service_app_download_url"];

          setStorage("avatarId", accountAvatarId);
          setStorage("uname", accountUserName);
          setStorage("balance", accountBalance);
          setStorage("cbalance", accountCommissionBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("wbalance", accountWinningBalance);
          setStorage("accountLevel", accountLevel);
          setStorage("accountLastActive", accountLastActive);
          setStorage("minwithdraw", service_min_withdraw);
          setStorage("minrecharge", service_min_recharge);
          setStorage("rechargeoptions", service_recharge_option);
          setStorage("telegramURL", service_telegram_url);
          setStorage("liveChatURL", service_livechat_url);
          setStorage("paymentURL", service_payment_url);
          setStorage("impMessage", service_imp_message);
          setStorage("appDownloadURL", service_app_download_url);
          // setStorage("winnersArr", JSON.stringify(data.winnersArr));
          setConstants({
            ...pageConst,
            accountBalance: accountBalance,
            isDataLoaded: true,
          });

          if (service_app_status == "OFF") {
            navigate("/um", { replace: true });
          } else {
            updateWinnersList(data.winnersArr);
            showNotice(data.noticeArr);
            updateSliderImagesArr(data.slideShowList);
          }
        } else if (data.status_code == "authorization_error") {
          if (deleteStorage()) {
            navigate("/login", { replace: true });
          }
        }
      } catch (error) {
        setLoadingShow(false);
      }
    };

    if (!isLoadingShow && !pageConst.isDataLoaded) {
      setLoadingShow(true);
      fecthApiData(API_ACCESS_URL + "?USER_ID=" + accountId, accountAuthSecret);
    }
  }

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random());
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  };

  const updateWinnersList = (data) => {
    let tempData = [];

    tempData.push(
      <div key={61}>
        <div className={[styles.winnersListStageView]}>
          <div className="col-view v-center mg-t--45">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[1]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no2_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no2_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[1]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[1]["winning_amount"]}
            </div>
          </div>

          <div className="col-view v-center mg-t--90">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[0]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no1_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no1_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[0]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[0]["winning_amount"]}
            </div>
          </div>

          <div className="col-view v-center mg-t--45">
            <div className="ps-rl">
              <img
                src={AVATARS_ARR[Number(data[2]["avatar_id"])]["avatarURL"]}
                className="h-w-60 br-50"
                alt="icon"
              />
              <img
                src={require("../media/icons/crown_no3_icon.png")}
                className="ps-ab ps-tp ps-lf--5 h-w-36 mg-t--20"
                alt="icon"
              />
            </div>

            <img
              src={require("../media/icons/win_no3_icon.png")}
              className="h-18-p z-i-1 mg-t--10"
              alt="icon"
            />
            <p className="ft-sz-14 cl-white mg-t-15">{data[2]["mobile_num"]}</p>
            <div className="ft-sz-12 pd-5-10 mg-t-5 cl-white br-15 bg-l-white-2">
              ₹{data[2]["winning_amount"]}
            </div>
          </div>
        </div>
      </div>
    );

    for (let i = 3; i < data.length; i++) {
      tempData.push(
        <div
          key={i}
          className={[
            general.rowView,
            general.spaceBetween,
            styles.winnerPersonView,
            i > 3 ? general.mgT10px : "",
          ].join(" ")}
        >
          <div className="row-view">
            <span className={[general.fontS20Px, general.width20px].join(" ")}>
              {i + 1}
            </span>

            <div className="h-w-45 ovf-hidden br-50 mg-l-40">
              <img
                src={AVATARS_ARR[Number(data[i]["avatar_id"])]["avatarURL"]}
                className="h-w-45"
                alt="icon"
              />
            </div>
            <span className={[general.fontS16Px, general.mgL10px].join(" ")}>
              {data[i]["mobile_num"]}
            </span>
          </div>
          <span className={[styles.winnerWonAmountView]}>
            ₹{data[i]["winning_amount"]}
          </span>
        </div>
      );
    }

    setConstants((previousState) => {
      return { ...previousState, winnersRecordList: tempData };
    });
  };

  const openGame = (url) => {
    if (getStorage("uid")) {
      navigate("/" + url, { replace: false });
    } else {
      redirectToLogin();
    }
  };

  const redirectToLogin = () => {
    deleteStorage();
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    let accountId = "";
    let accountAuthSecret = "";

    if (getStorage("uid")) {
      accountId = getStorage("uid");
      accountAuthSecret = getStorage("secret");
    } else {
      accountId = "guest";
      accountAuthSecret = "guest";
    }

    getAccountInfo(accountId, accountAuthSecret);
  }, [pageConst.sliderImages, pageConst.winnersList]);

  return (
    <div className={[general.viewCenter, general.appBackground].join(" ")}>
      <div
        className={[
          general.height100vh,
          general.respWidth,
          general.overflowScrollY,
          general.appContentsBackground,
          general.hideScrollBar,
        ].join(" ")}
      >
        <NoticeDialog
          intentData={noticeDialogConst}
          updateState={updateNoticeDialogState}
        />

        <div className={[styles.homeView, general.posRelative].join(" ")}>
          <div
            className={[
              styles.topBar,
              general.rowView,
              general.spaceBetween,
              general.width100,
              general.appContentsBackground,
            ].join(" ")}
          >
            <img
              className={`h-40-p mg-l-10`}
              src={require("../media/icons/app_logo_fill.png")}
              alt="icon"
            />

            <div className={[!getStorage("uid") ? general.hideView : ""]}>
              <img
                className={`h-25-p`}
                src={require("../media/icons/live_support_icon.png")}
                alt="icon"
                onClick={() => openNewPage(getStorage("liveChatURL"))}
              />
              <img
                className={`h-25-p mg-l-15`}
                src={require("../media/icons/download_icon.png")}
                alt="icon"
                onClick={() => openNewPage(getStorage("appDownloadURL"))}
              />
            </div>

            <div
              className={[
                general.rowView,
                !getStorage("uid") ? "" : general.hideView,
              ].join(" ")}
            >
              <Link
                className={[
                  general.textDecoNone,
                  general.height30px,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS14Px,
                  general.pd5px15px,
                  general.borderRadius,
                  general.bgCustomColor3,
                ].join(" ")}
                to={"/login"}
              >
                Login
              </Link>

              <Link
                className={[
                  general.textDecoNone,
                  general.height30px,
                  general.viewCenter,
                  general.colorWhite,
                  general.fontS14Px,
                  general.pd5px15px,
                  general.mgL10px,
                  general.borderRadius,
                  general.bgCustomColor2,
                ].join(" ")}
                to={"/register"}
              >
                Register
              </Link>
            </div>
          </div>

          <div className={[general.pd15px]}>
            <SlideShow dataList={pageConst.sliderImages} />
          </div>

          <div className="pd-0-15">
            <ImportantNotice />
          </div>

          <div className={[general.rowView, general.alignFlexStart, general.mgT20px, styles.pd10px].join(" ")}>
            <div className={[styles.homeGameCategory]}>
              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(1)}
              >
                <img
                  src={require("../media/icons/backgrounds/11.png")}
                  alt="icon"
                />
                <span>Slots</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(0)}
              >
                <img
                  src={require("../media/icons/backgrounds/15.png")}
                  alt="icon"
                />
                <span>Lottery</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(0)}
              >
                <img
                  src={require("../media/icons/backgrounds/14.png")}
                  alt="icon"
                />
                <span>Original</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(3)}
              >
                <img
                  src={require("../media/icons/backgrounds/12.png")}
                  alt="icon"
                />
                <span>Casino</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(4)}
              >
                <img
                  src={require("../media/icons/backgrounds/8.png")}
                  alt="icon"
                />
                <span>Rummy</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(5)}
              >
                <img
                  src={require("../media/icons/backgrounds/9.png")}
                  alt="icon"
                />
                <span>Fishing</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(6)}
              >
                <img
                  src={require("../media/icons/backgrounds/7.png")}
                  alt="icon"
                />
                <span>Sports</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(0)}
              >
                <img
                  src={require("../media/icons/backgrounds/16.png")}
                  alt="icon"
                />
                <span>Popular</span>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.alignCenter,
                  styles.bgInActive,
                ].join(" ")}
                onClick={() => setViewBoxNumber(4)}
              >
                <img
                  src={require("../media/icons/backgrounds/8.png")}
                  alt="icon"
                />
                <span>Rummy</span>
              </div>
            </div>


            <div
              className={[
                styles.flex,
                styles.colView,
                general.mgL10px,
                styles.stickyView,
                viewBoxNumber != 0 ? styles.hideView : "",
              ].join(" ")}
            >
              <div
                className="ps-rl txt-deco-n br-10 bg-white"
                onClick={() => openGame("wingo1min")}
              >
                <div className={[styles.homeGameViewBox]}>
                  <img
                    className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                    src={require("../Games/Wingo/icons/4.png")}
                  />
                  <div className="col-view ps-ab ps-lf ps-tp pd-15">
                    <span className="ft-sz-23 ft-wgt-500 cl-white">Win Go</span>
                    <span className="ft-sz-12 cl-white mg-t-10">Guess Number</span>
                    <span className="ft-sz-12 cl-white">
                      Green/Red/Purple to win
                    </span>
                  </div>

                </div>
              </div>

              <div
                className="ps-rl txt-deco-n br-10 mg-t-30 bg-white"
                onClick={() => openGame("k3lottery1min")}
              >
                <div className={[styles.homeGameViewBox]}></div>
                <img
                  className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                  src={require("../icons/dice_lottery_icon.png")}
                />
                <div className="col-view ps-ab ps-lf ps-tp pd-15">
                  <span className="ft-sz-23 ft-wgt-500 cl-white">K3 Lottery</span>
                  <span className="ft-sz-12 cl-white mg-t-10">Guess Number</span>
                  <span className="ft-sz-12 cl-white">Big/Small/Odd/Even</span>
                </div>
              </div>

              <div
                className="ps-rl txt-deco-n br-10 mg-t-30 bg-white"
                onClick={() => openGame("AndarBahar")}
              >
                <div className={[styles.homeGameViewBox]}></div>
                <img
                  className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                  src={require("../icons/5dlottery_icon.png")}
                />
                <div className="col-view ps-ab ps-lf ps-tp pd-15">
                  <span className="ft-sz-23 ft-wgt-500 cl-white">
                    Andar Bahar
                  </span>
                  <span className="ft-sz-12 cl-white mg-t-10">Guess Number</span>
                  <span className="ft-sz-12 cl-white">
                    Green/Red/Purple to win
                  </span>
                </div>
              </div>

              <div
                className="ps-rl txt-deco-n br-10 mg-t-30 bg-white"
                onClick={() => openGame("Wheelocity")}
              >
                <div className={[styles.homeGameViewBox]}></div>
                <img
                  className="h-80-p ps-ab ps-rgt-10 ps-tp--15px"
                  src={require("../icons/trxlottery_icon.png")}
                />
                <div className="col-view ps-ab ps-lf ps-tp pd-15">
                  <span className="ft-sz-23 ft-wgt-500 cl-white">Wheelocity</span>
                  <span className="ft-sz-12 cl-white mg-t-10">
                    Guess Animal Number
                  </span>
                  <span className="ft-sz-12 cl-white">
                    Elephant/Tiger/Crown to win
                  </span>
                </div>
              </div>

            </div>
          </div>


          <div
            className={[
              styles.homeAdditionalGamesTitle,
              viewBoxNumber != 1 ? styles.hideView : "",
            ].join(" ")}
          >
            <span></span>
            JILI
          </div>

          <div
            className={[
              styles.homeAdditionalGamesView,
              viewBoxNumber != 1 ? styles.hideView : "",
            ].join(" ")}
          >
            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjK5rhHp1ykx6bsq7LTPzClgBtLLrXeveAVGr9-iT2W12NePqc0HT5-hP2cXt5onZ1sG58rnfz9hhAutG6qjkkbT9P7OZwlcJ0IhRQFGMPVR-NjUpDk4n0LX5zS00oz4VQYLzlyhe06KHMVxS0pjnbsdnHuWSqLeWeIrr_EAkJRcLRymsP5C2tXWrzcxRo/s1600/229.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhON4fbjEiuOkYoKBpJtIaLeRU2vVIEierFp_VGldaHcecnDogL0m9G2x0WdxQxok7XtvrUMGaScflLo5Qxa82uvb0QS_TMberrK6Z_BbCfmnLvKOoXgkl7z78cb8kaq1zh1dui-CcjmyKDUwnCwDucY7zihe3hkxJWBJhoJ9v6mkqqMb4EMIKoiHSqKNw/s1600/236.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgKguOGsbOGEtIb0QW2XofOhTOZgmrQbJV6hW5muKutqpYQFukwOGmrqj6XSf6o2euM5uPP4YuemLI6ZqUs3FOZ25y-6Max4pTudOmd29zImemUx_qidvn9WLjToZJMrzHQrV2KvjcASMXNlbh_-_qZvh6g9MaO1WwaJfXv44s2bO0NteJagEu30PVDe0I/s1600/197.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhH0hlpK4zabi12fLWFTeAeBZ5HLfmPb4Vrodis6x0qV01GqSUnGt1HVYEWaQNzcpbNa8J9kOs79PDYBrDJ4HPt4gAgrDxjAqXHuUyd5Kx55crz_3Lm4hQE5XV4eTzjreNYT_sTBRGhlXBIGpWyzXz7IH8MeHxFtgAdizWSH5SVpf3EFs6zuY89dNqWT2Y/s1600/233.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhFMCWQasnpfqXPkwW91-8snV5cuyzaay9q3q_kaK_nR7wonU3JfIno5mgd-4h2mwKDH9dphi9kUtxrKpt9rGFOG-zrGqTQ8jc7pnCxOmmjfhXdm_yGiOrbAt5Pgh1DvhDqwR1IKAhJw1K1EEf5DAhKj5_peME8p5Nj_EkFH1aLjoGqhrKGowUKnXUcD0o/s1600/224.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEio7dJSCLLWLG3vZpj12-ZN5p6bhl_XodJkFEUy1bWpFWsNK8YtoZH9eCwr8Gqa6Zn8unZidgmfWhcDhDTuinvANKbWYaMHogA-I7nlkrgGyWcaiFqLWrQ5H2VaeQYMhY2cRhfgQMlIQEmUFJ1DyqxpfyHB1l8IC-e_B9EObyeXIIZ_sJcGBuSi1ON9OVc/s1600/1.png"
                alt="icon"
              />
            </div>
          </div>

          <div
            className={[
              styles.homeAdditionalGamesTitle,
              viewBoxNumber != 4 ? styles.hideView : "",
            ].join(" ")}
          >
            <span></span>
            Rummy
          </div>

          <div
            className={[
              styles.homeAdditionalGamesView,
              viewBoxNumber != 4 ? styles.hideView : "",
            ].join(" ")}
          >
            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhzldaiHbPO4tsq1eoIK1LeeXY9tJpqM3JeIqvsmdxu9QJjDp3kAGqt5Q0VaJnJSsemhjof4A2k3aRsw3DQP6DtJjL2RRglZ7wXzQqZMM7pTRJ555rF2MKDA4T_uYNdueOOJHNttyL949EUAALPlv5PE3NvA0MoeLyNFOP5G_AHRpmtGCvgddbHP3Ts-2g/s1600/vendorlogo_202401161815294l5u.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgtjUS1d1BwyARwM_CikTdgpjr4aFUdaA8M_kvCHw9hcDxRgW6lWUDal0epM3qvxkX82gFh5RrM6fz9GTBblLYiTp4ZOVk1qkjVc7snIYLqo5fP_wjwPtOqxD4UFIoEX2Rhk9_y2za4lcHtQGZ2knkaU6mFYuukfs9Hm_tL56sEgsS4IK7Sj1JmSFV-gOA/s1600/vendorlogo_202401161815398gx3.webp"
                alt="icon"
              />
            </div>
          </div>

          <div
            className={[
              styles.homeAdditionalGamesTitle,
              viewBoxNumber != 5 ? styles.hideView : "",
            ].join(" ")}
          >
            <span></span>
            Fishing
          </div>

          <div
            className={[
              styles.homeAdditionalGamesView,
              viewBoxNumber != 5 ? styles.hideView : "",
            ].join(" ")}
          >
            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgJTFQKbb1efbsqjZzjwmryxzWog7K_BczG_7EFAdIU9S6uxxSoG_kUiczb769MEVn6VZ6-N5LEr4h1kCupW_aUoBf6IdKhhzB2vq17O6hQ4LExHc4ulxX7FBrvlpwsDfSPZvjcjzYhyphenhyphenHCmA2EfU_I6JrNo9ljUWK0nap-yxwy8O5YI1jnRQu8VU789CV4/s1600/32.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjH4obr4TOcPRKGqGEMwcHzs5DlFkBgsWle5-IglZbrwZYDNQQns76gMB8chcOSma_TYJnJZ3jh4hbGfN11cF3wYjZWSqKHi6VxTfA-EEKqE1US7n679elFLQqAEjvLxSH8r_uSLoqvIYvabmQ7HBLu5Uc6tL7iEwz5iQ8lFp3yhXjbN3Lp_ATykj4tHP4/s1600/71.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgIEUdOedDNM9MTPbXAjxI60x8BpNG9JEfP-C4KjQBk12kazaCKZKrhaysi-7OkTCW1zD_0FhXyX8qtly2YamTwVHSxuW7bizJFxMhH-kKw_VN9tjxzghPmutiAaWJw0IfkilA-SI3rVVlrpDy1YUP9wR1z07A8bBr9D5i0S11DiSHWOmycRLFzvJq-hCM/s1600/7001.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiOmH3sw2CD8NPxbkJq_WsQW4DmGlVxG2mm7_lcNbdYsgcVXCwVfLQ1_V9ACz0brMnACi9cLqM4f0X51XtpA3Lap3S6so6K2lDZgzmW6m92PXZ_hDUMbZs8WYBYbZrhkVF93h9o65Dok-ZKjFrSr4n71Ruz5x2AJtfMRhf9WbLDAq3dJoTdoUHxkuIOGJ4/s1600/7002.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjuog3WCgxdiw1fMy9QtAzAlwhJsGScCXzockGLw9y3Kk6XKTJTtkDIrHvWKzph1TZGho_6Kw8YmfmFcosxNWwdHfLeyvK4Fr4Whn9EIzbjjJ97XHArKh7AjEEwD8JlFo-yAXd_5YxyhBmmK1us9agp_UiCIdJQhMjvxfcCbmT4FEWSRhRfmPL-gazcEeY/s1600/7003.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjSS0IoKgDDO774zC1mlOkTNXtCU4bEc6S8AEQhNq5Kq2AZbiD1ugdjysl8qIC2cQfO6e3oQa0VMuFG6ju5t3V776hQ5m-ZfvaeS-qPRXNY-0MZEuqx0jSfZ6igZyTylr8OK1my2OcJXwF9pKpNu-E7EGq4WZlOtOetZ9xNQjIHX1MvbjSJtLifpyi0cE0/s1600/7006.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjUg3OqQz1XTa1rpPtjLxbPsgMtSGsEO_3iYVadZcz-8S_9c85guU8LL3l3_MdjiwJvrVxYrBg6ic6ntqoCnJvmyotZUWJz0YU8mTZFgwJpPJxWxXQlAep3B5YbQiF3384nEmxrJ0A3Hvr1PiQDBq5Cj5doJaladpHuRhaZ0SWHNb30dFZxsri4qO8UgIY/s1600/7007.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhUpOLX8Tht0-2mytNVvsRkvt5VyNnv0UjKOHE4sLotr8xqHCM-SfhvCGAAw9WzyNHZVc5i_WlWUlsK56uxQToo_CVMd_LeEyYX1bbLY2zdi5dRa9Ajq_f6xziYXzHriPoFb5XPGgbpHSCG0H8d6Cvh0qjqAt0poJE_7Q0K8EaYxeyh2IDgpJhG_1kjVK0/s1600/67001.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEg-3iSFsbc5I1us7DQtDvzWZ8_UESVRu9h6c0a2CSXN9LJ94zkHlNCFPkiAWEcl-LLYRFRPmQkFtS-COUgCuruygfDpEEdRoySy63ES0XAnBKQaPb2AbOxH4TjhsbcFs1Sbdlb8grJRpG9dPFseSxmHd1UdXc-q1PUf70UwYVBKt2r02CyM_U5Z-uS0Nu0/s1600/AB3.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgKMLgeVkKyJArPig5iYmDasPTHeHjnmbMIWil-_OkYTrwU7KSVF8edS72j5JhodLLWZQT8ggTLo4wGFlH9Qqy81GzCSZrkPSyWv0BTODa7fn4iTOf59DbvnbP_EGlGP2cntHU83GoEwj17v80MaB1z8vvoirasRXjWKcb9t_s_hBRVcrpv6lmAmBhwp0U/s1600/AT01.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgb6P8ljzzhmmJ5hNg3hnrChY0bGx7D4TXJujvuOnNak5cRYgeinz3NY4uidKYj8-DHiK5gRIHm-TYkdDVO6jEM514tFfzl0VBYZXXc1pkBb1f7YgmZi90BH1cF7Y_KFDnKx5h30sMxJH3E9Xv6QZvszdkuUyPCGPqAwQ-J6_BJDYoH3sdwC4AGm29weDo/s1600/AT05.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjzgMBa3a4H1aKWz0EPCvieFSkJK4XkBNscmyrf9BkTeLZxNrZEQ-50eahMRyL8em4dEsjmLmFOtmVMtGy-WwqidhLFS0_E0CQxxTgWmWpar90RBuRhL_puKXFnxTakQe-cyGCHlnhnngCTdJ3pQuUIc0v3Ob-8l3J8RkX9FI1-FXrayXvgbJV-bOLk7U0/s1600/GO02.webp"
                alt="icon"
              />
            </div>
          </div>

          <div
            className={[
              styles.homeAdditionalGamesTitle,
              viewBoxNumber != 6 ? styles.hideView : "",
            ].join(" ")}
          >
            <span></span>
            Sports
          </div>

          <div
            className={[
              styles.homeAdditionalGamesView,
              viewBoxNumber != 6 ? styles.hideView : "",
            ].join(" ")}
          >
            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh5eL9UxL9r7bnXgHkmnWqrWmvuU-mxdyLRb3SWh8aqQAMSvIvwdm_1fq1QIs27cgdnYScx28Ns0ayLVz_3ocan4PKx3CNSci69CjlNAO-FeFwjQWdMXJzxutEiB5vPVXa_MWujI7MpUmnaWUbdNocD2wx_EnDOapU9iju2LFAuyJ_tzh46eAHOK4b7LVs/s1600/vendorlogo_20240116180850im3d.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgjjXNO5yvUbL55OknSyGBv7sjlLbeh_nRF5Tah6BJAntTnn5HTuesip35apDCHGhLIOVxbgls723ZIA2HG_gDv_Y15Bz7dZeMZhPuEJfUDmOhdKqKmN5MPYC_ity112COEUJ6QOJSSL3prDZN5SM2CydIR88Q_y1KhWvAyay-0jrsd8gyAIwIEGWnChfU/s1600/vendorlogo_20240116181011v5fb.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiuO1OXb-HzG__MTNGweU_sgMr0uAxHS96_RACrJHhF34GgTV4fk1zxvaM0ZDOuTJz1O1Bc0IRIjfucsKj8rhuydrg6V2b1ticCQ2f3I5JLfeCczbQK7EoD-ikJOWaEXxuBsAICO-3aNhxSah6LyO6Oxkys_kmBakAJ3ilUbIXu83vcRJTsE_wUmYIlp5s/s1600/vendorlogo_20240116181140kbrq.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjTGcoSk6KOPSvb7Av3wC3n4ksJ8RYiaAyvPZzf8pvWyx13iDGzsasYYDIJKXfNfHsNnKwtyC11AgAdHu5wlZ6d4uZiqmiyBKbOdUoUuqEX0B0NOC6uPTHyUb9Zkbg5k1BsBWiR3_r84Z8Xy4rlkmlIOS7ghJ3VOQ4ZyZonRD9oMlZUCLweuvI39Jqi8BA/s1600/vendorlogo_20240116181151vd4w.webp"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgFpXUGFtEct2gewiuzo8EVmEhqEn4d3W_7QXd4oQBO6Rt5ozIuxLkc7KMEaJRfGAvWpAiyhRizF7JTgg4tbYyPt_C_lCc2DVDKeCUR-bi2qeyurCT0AR8XNsKV3x6-d_4HMJhNiET4ZxkuVIysAQN1kwJ6b3AyhIlWCyx7rO5rLN5RLQsELsjmEvZGRF4/s1600/vendorlogo_20240116181202hhjt.webp"
                alt="icon"
              />
            </div>
          </div>

          <div className={[styles.homeAdditionalGamesTitle]}>
            <span></span>
            PG
          </div>

          <div className={[styles.homeAdditionalGamesView]}>
            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjAbIvOoqoDeTTsiXAoMQ70UD8NFpPcV1jU4BE5mWGGgcPBwwI5wt1zRSXx5aDaNiKgaCPmnce-y3FoJCJwTB3-RYmRMOOIaA7gA_fbdZlOMrvV0NtN_n87AS2MPtC9Rz_zFdPXLlC2krpJJLbGYCcZBJod62dG1EMXW2TjG5cvJ02CQQAopGtQS5436fo/s320/103.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgxZ7xCLDm1Oq-IDs7PFUz38nHSC4p73oXOouZJuhmf2lV0KeZ3xi_Lb9MrbBZa9C-GRk_QqfL6ptWl6xyZejNt_FYL9vKHXtM_p6P6tPGP0sMFTm5Qf04F_eSFr4y8oU4_5IXHPk5b2jDE6okdUnzEprChCg07dXZu17xDa_rJdHcenHuq_AS-sNcNulw/s320/101.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEhJ6CYVZ7ts3uwCPm6na-SzEnfJcUd2I7aE4IlkWsnnHSR3lHUiMQw6omJ3oPp8Pxt_XwSIGrPHyPvCssk_anbfoF0XkDuxv6sjUmD39bqKxZiEmW2ACLx43GkXWtzJmG3yEUCfVOpJI0ubKEpP0-pgIQh9wKfoh97RCZbOuHw9G5fANs9KcWlG1kznjg4/s320/100.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEha0vfI1UgFU6vQVpWRTOHQr40JUzwSdN40aAcotsImAFohxGWjW0phc9RiCo9Zh7y3TxOA9nc7DG8psUpkIQorAmPmmXIMmrxcJtMJkzrgu5rGi8L6Z9FYJlzjoiKzk73YSQ0-5pR0LusP57WsIKHK7-n9iEgS-AkzTwO1v1GAdSWZJeTKPOgU5zim-cs/s320/1543462.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgpVzYw-qCX7nFQ8HthCSUPKBwkmvFw26bCKc-9elw-9Tc8xMtaRQTa7qgOHibhyXj5RNDmLDXAjJaBursqUMxmDyvf2ecCATNn_UiZUw-fA_3xXNuCX3SodcIHnGpWcf3gpx19JKdu2jrNGBjyNDHmPeyyglF_w6Z8W9JPxLIAPWVyYXI0qvh0wW1P2yc/s320/98.png"
                alt="icon"
              />
            </div>

            <div>
              <img
                src="https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgBFqmm4PJHbU1SsNsnOENJfHm7Z6fmVw_D_v4NXxAiMM0JvEiijGXBCpZKgoSSSyYQOrS6FVWn_jLLzlRLmGaJgTCklLfyPRZuMgRfugFHG-0tvHGzvIbY6zzQZpfc1mWqC-Kfa6EgU2pSSl9_aQKw1Ez5VuJi8FNksDXGZ1MyVI-nb0633GC40uV-prY/s1600/79.png"
                alt="icon"
              />
            </div>
          </div>

          <div className="min-h pd-15 mg-t-20 mg-b-10em">
            <span>Top Earner's List</span>

            <div className="col-view">{pageConst.winnersRecordList}</div>
          </div>
        </div>

        <BottomNavbar activeBar="home" />
      </div>
    </div>
  );
}

export default Home;
